export const ImgMediaType = {
  apng: 'image/apng',
  avif: 'image/avif',
  gif: 'image/gif',
  jpg: 'image/jpeg',
  png: 'image/png',
  svg: 'image/svg+xml',
  webp: 'image/webp',
} as const

export type ImgMediaType = typeof ImgMediaType[keyof typeof ImgMediaType]

export type ImgSourceType = {
  srcset: string
  media?: string
  type?: ImgMediaType
}

export type ImgType = {
  src: string
  alt: string
  width: number
  height: number
  sources?: ImgSourceType[]
}
